import React from "react";

export default function NoMoreSongs() {
  return (
    <div>
      <article className="d-flex justify-content-center">
        <div style={{ width: "100%" }}>
          <iframe
            title="No More Songs"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              border: 0,
              width: "100%",
              height: "950px",
            }}
            src="https://bandcamp.com/EmbeddedPlayer/album=4190978381/size=large/bgcol=333333/linkcol=ffffff/artwork=big/transparent=false/"
            seamless
          >
            <a href="https://davidstanfel.bandcamp.com/album/no-more-songs">
              No More Songs by David Stanfel
            </a>
          </iframe>
        </div>
      </article>
    </div>
  );
}
