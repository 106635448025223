import React from "react";
import { AppContext } from "./AppContext";

export default function SectionDescription() {
  const ctx = React.useContext(AppContext);
  const getText = () => {
    switch (ctx.viewing) {
      case "usa-photos":
        return `
                  "USA" is a series of photos taken on a road trip of the 
                  United States between April and June 2019. Starting in 
                  South Carolina, the series progresses through the South,
                  heading west through Texas and the Rockies towards the Pacific Ocean. Upon
                  arrival in California, the series progresses north towards Washington before
                  turning back east through Montana, ending in the Badlands of South Dakota.
                `;
      case "lighthouses":
        return `
                  "Lighthouses" is a series of photos taken in the Outerbanks of 
                  North Carolina in March 2020, just days before the 
                  pandemic lockdown began. These photos display the quaint yet robust
                  structures which guided sailors of the 19th and 20th century.
                `;
      case "planes":
        return `
                  "Airplanes" is a series of photos taken in December 2018 under the runway of Raleigh
                  Durham International Airport. These photos capture a number of commercial airplanes
                  during take off and landing. 
                `;
      case "powerlines":
        return `
                  "Transformer" is a series of three images of a local transformer in Raleigh, NC.
                  These photos demonstrate the rigid symmetry and industrial brawn of the
                  electrical infrastructure we rely on.
                `;
      case "smokies":
        return `
                "Smokies" is a series of photos taken on a three day hike in the Great Smoky Mountains
                of North Carolina and Tennessee. The series features the colorful leaves of fall that 
                can be seen throughout the Appalachian Mountains.
                `;
      case "hands":
        return `
                    "Hands" is a series of images taken at home in Chapel Hill. The intent with these
                    images is to examine the physicality and meaning behind various gestures made with
                    the most versatile human appendage.
                `;
      case "mouthing-off":
        return (
          <div>
            <p>Mouthing Off</p>
            <p>
              All songs were written and recorded between June 2020 and May
              2022. Most songs feature live guitar, bass, vocals, and programmed
              drumming, with some deviations from the norm. Thank you for
              listening.
            </p>
            <p>credits released July 15, 2022</p>
            <p>Max Roach - Drums</p>
          </div>
        );
      case "no-more-songs":
        return (
          <div>
            <p>No More Songs</p>
            <p>
              All songs written and recorded at home from as early as 2016 and
              as late as April 2020 in the Piedmont of NC. Joshi's Ballad was
              recorded in Chino Hills State Park, Chino Hills, CA and is
              dedicated to Josh Kakarlamudi.
            </p>
            <p>released May 15, 2020</p>
          </div>
        );
      default:
        return "";
    }
  };
  return (
    <div
      className="row my-4 mx-3 description"
      style={{
        borderTop: "2px solid #3a523b",
        display: ctx.show ? "" : "none",
      }}
    >
      <div className="col py-3" style={{ fontSize: "9pt" }}>
        <article>{getText()}</article>
      </div>
    </div>
  );
}
