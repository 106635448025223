import figlet from "figlet";
import Isometric1 from "figlet/importable-fonts/Isometric1";
import Isometric2 from "figlet/importable-fonts/Isometric2";
import Isometric3 from "figlet/importable-fonts/Isometric3";
import Isometric4 from "figlet/importable-fonts/Isometric4";
import React from "react";
import { useMediaQuery } from "react-responsive";

export const FONTS = [
  { name: "Isometric1", file: Isometric1 },
  { name: "Isometric2", file: Isometric2 },
  { name: "Isometric3", file: Isometric3 },
  { name: "Isometric4", file: Isometric4 },
];
export const AppContext = React.createContext({
  fontIdx: 0,
  setFontIdx: () => {},
  viewing: "mouthing-off",
  setViewing: () => {},
  getFig: () => {},
});

export default function AppProvider({ children }) {
  const [fontIdx, setFontIdx] = React.useState(0);
  const [viewing, setViewing] = React.useState("mouthing-off");
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });
  const [show, setShow] = React.useState(!isMobile);
  FONTS.forEach((font) => {
    figlet.parseFont(font.name, font.file);
  });
  const getFig = React.useCallback(() => {
    return figlet.textSync("david.\n\nstanfel.\n\nnet", {
      font: FONTS[fontIdx].name,
    });
  }, [fontIdx]);

  const contextVal = {
    fontIdx,
    setFontIdx,
    viewing,
    setViewing,
    getFig,
    show,
    setShow,
  };
  return (
    <AppContext.Provider value={contextVal}>{children}</AppContext.Provider>
  );
}
