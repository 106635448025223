import React from "react";

export default function ManifestMapper({ manifest }) {
  if (manifest.length > 0) {
    return manifest.map((img, idx) => (
      <div key={idx}>
        <article className="d-flex justify-content-center">
          <div>
            <img
              alt={`${img.title}`}
              className="img-fluid py-2"
              src={img.url || `${process.env.PUBLIC_URL}/img/${img.obj_key}`}
              style={{ maxHeight: "850px", width: "auto" }}
            />
            <div className="my-1" style={{ fontSize: "12px" }}>
              <p className="my-0">{img.title}</p>
              <p className="my-0">
                {img.location} {img.year}
              </p>
            </div>
          </div>
        </article>
      </div>
    ));
  }
  return null;
}
