import React from "react";

export default function AboutModal() {
  return (
    <div className="modal fade" id="aboutModal">
      <div className="modal-dialog modal-lg">
        <div
          className="modal-content"
          style={{ backgroundColor: "#06031a", color: "whitesmoke" }}
        >
          <div
            className="modal-header"
            style={{ borderBottom: "2px solid #3a523b" }}
          >
            <h5 className="modal-title px-4 py-2" id="aboutModalLabel">
              About
            </h5>
            <button
              type="button"
              className="btn-light"
              data-bs-dismiss="modal"
              aria-label="Close"
              style={{
                border: "none",
                background: "none",
                borderRadius: "2px",
                color: "whitesmoke",
              }}
            >
              X
            </button>
          </div>
          <div className="modal-body px-4 py-4">
            <p>
              David Stanfel is a software engineer, musician, and hobbyist
              photographer with a B.S. in Computer Science from the University
              of North Carolina at Chapel Hill.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
