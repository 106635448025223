import React from "react";

export default function MouthingOff() {
  return (
    <div>
      <article className="d-flex justify-content-center">
        <div style={{ width: "100%" }}>
          <iframe
            title="Mouthing Off"
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              border: 0,
              width: "100%",
              height: "950px",
            }}
            src="https://bandcamp.com/EmbeddedPlayer/album=138308432/size=large/bgcol=333333/linkcol=ffffff/artwork=big/transparent=false/"
            seamless
          >
            <a href="https://davidstanfel.bandcamp.com/album/mouthing-off">
              Mouthing Off by David Stanfel
            </a>
          </iframe>
        </div>
      </article>
      <article className="d-flex justify-content-center">
        <iframe
          width="100%"
          height="500"
          style={{ marginRight: "8%", marginBottom: "20px", marginTop: "20px" }}
          src="https://www.youtube.com/embed/stAHztaAXBk"
          title="Autumn"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </article>
      <article className="d-flex justify-content-center">
        <iframe
          width="100%"
          height="500"
          style={{ marginRight: "8%", marginBottom: "20px", marginTop: "20px" }}
          src="https://www.youtube.com/embed/hDcUNRQNteg"
          title="2020 / These Uncertain Times"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </article>
    </div>
  );
}
