import AboutModal from "./AboutModal";
import Nav from "./Nav";
import React from "react";
import SectionContent from "./SectionContent";
import { AppContext, FONTS } from "./AppContext";

export default function Body() {
  const ctx = React.useContext(AppContext);

  return (
    <div
      onClick={() => {
        let newFontIdx = ctx.fontIdx < FONTS.length - 1 ? ctx.fontIdx + 1 : 0;
        ctx.setFontIdx(newFontIdx);
      }}
    >
      <div className="container-fluid">
        <div className="row">
          <Nav />
          <SectionContent />
        </div>
        <AboutModal />
      </div>
    </div>
  );
}
