import axios from "axios";

export async function getManifest(manifestUrl) {
  let response;
  try {
    response = await axios.request({
      method: "get",
      url: manifestUrl,
    });
  } catch (error) {
    if (error?.response) {
      response = error.response;
    }
  }
  return response;
}
