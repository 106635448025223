import ManifestMapper from "./ManifestMapper";
import React from "react";
import { getManifest } from "./assets";

export default function PhotoSeries({ manifestUrl }) {
  const [manifest, setManifest] = React.useState([]);
  const fetcher = React.useCallback(async () => {
    const resp = await getManifest(manifestUrl);
    if (resp?.status === 200) {
      setManifest(resp.data);
    }
  }, [manifestUrl]);

  React.useEffect(() => {
    fetcher();
  }, [fetcher]);
  return <ManifestMapper manifest={manifest} />;
}
