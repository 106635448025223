import MouthingOff from "./MouthingOff";
import NoMoreSongs from "./NoMoreSongs";
import PhotoSeries from "./PhotoSeries";
import React from "react";
import { AppContext } from "./AppContext";

export default function SectionContent() {
  const ctx = React.useContext(AppContext);
  const getContent = React.useCallback(() => {
    switch (ctx.viewing) {
      case "mouthing-off":
        return <MouthingOff />;
      case "no-more-songs":
        return <NoMoreSongs />;
      default:
        return (
          <PhotoSeries
            manifestUrl={`https://assets.david.stanfel.net/${ctx.viewing}/assets-manifest.json`}
          />
        );
    }
  }, [ctx.viewing]);
  return (
    <div className="row py-4">
      <div className="scroll col-md offset-sm-4">{getContent()}</div>
    </div>
  );
}
