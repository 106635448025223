import "./App.css";
import AppProvider from "./AppContext";
import Body from "./Body";
import React from "react";

const App = () => {
  return (
    <AppProvider>
      <Body />
    </AppProvider>
  );
};

export default App;
