import "./Nav.css";
import ListIcon from "./ListIcon";
import React from "react";
import SectionDescription from "./SectionDescription";
import { AppContext } from "./AppContext";

function Music({ click, open }) {
  const ctx = React.useContext(AppContext);
  const getMusicTreeStyle = React.useCallback(() => {
    if (open) {
      return { height: "45px" };
    }
  }, [open]);
  return (
    <li>
      <button className="linkTree px-0 py-0" onClick={click}>
        Music
      </button>
      <div className="unfold" style={getMusicTreeStyle()}>
        <ul>
          <li>
            <button
              className="linkTree px-0 py-0"
              onClick={() => ctx.setViewing("mouthing-off")}
            >
              Mouthing Off
            </button>
          </li>
          <li>
            <button
              className="linkTree px-0 py-0"
              onClick={() => ctx.setViewing("no-more-songs")}
            >
              No More Songs
            </button>
          </li>
        </ul>
      </div>
    </li>
  );
}

function Photo({ open, click }) {
  const ctx = React.useContext(AppContext);
  const getPhotoTree = () => {
    if (open) {
      return { height: "135px" };
    }
  };
  return (
    <li>
      <button onClick={click} className="linkTree px-0 py-0">
        Photos
      </button>
      <div className="unfold" style={getPhotoTree()}>
        <ul>
          <li>
            <button
              className="linkTree px-0 py-0"
              onClick={() => ctx.setViewing("usa-photos")}
            >
              USA
            </button>
          </li>
          <li>
            <button
              className="linkTree px-0 py-0"
              onClick={() => ctx.setViewing("lighthouses")}
            >
              Lighthouses
            </button>
          </li>
          <li>
            <button
              className="linkTree px-0 py-0"
              onClick={() => ctx.setViewing("planes")}
            >
              Airplanes
            </button>
          </li>
          <li>
            <button
              className="linkTree px-0 py-0"
              onClick={() => ctx.setViewing("hands")}
            >
              Hands
            </button>
          </li>
          <li>
            <button
              className="linkTree px-0 py-0"
              onClick={() => {
                ctx.setViewing("smokies");
              }}
            >
              Smokies
            </button>
          </li>
          <li>
            <button
              className="linkTree px-0 py-0"
              onClick={() => ctx.setViewing("powerlines")}
            >
              Transformer
            </button>
          </li>
        </ul>
      </div>
    </li>
  );
}

export default function Nav() {
  const ctx = React.useContext(AppContext);
  const [openTree, setOpenTree] = React.useState({ music: true, photo: false });

  const getClick = React.useCallback(
    (key) => {
      return () => {
        const treeCopy = { ...openTree };
        Object.keys(treeCopy).forEach((k) => {
          treeCopy[k] = false;
        });
        treeCopy[key] = !openTree[key];
        setOpenTree(treeCopy);
      };
    },
    [openTree, setOpenTree]
  );
  return (
    <div className="col-sm-4 py-3 sidebar">
      <pre className="ps-3 d-none d-sm-none d-md-none d-lg-block fig-pre">
        <span>{ctx.getFig()}</span>
      </pre>
      <ul className="nav navigation ps-4 nav-ul">
        <li className="d-block d-sm-block d-md-block d-lg-none">
          David Stanfel
        </li>
        <div className="col text-end pe-5 py-2">
          <ListIcon
            click={() => {
              ctx.setShow(!ctx.show);
            }}
            display={!ctx.show}
            width="24"
            height="24"
          />
        </div>
        <div className={`collapse${ctx.show ? " show" : ""}`}>
          <Music open={openTree.music} click={getClick("music")} />
          <Photo open={openTree.photo} click={getClick("photo")} />
          <li>
            <button
              className="linkTree px-0 py-0"
              data-bs-toggle="modal"
              data-bs-target="#aboutModal"
            >
              About
            </button>
          </li>
          <li>
            <a href="mailto:david@stanfel.net" className="linkTree px-0 py-0">
              Contact
            </a>
          </li>
        </div>
      </ul>
      <SectionDescription />
    </div>
  );
}
