import React from "react";

export default function ListIcon(props) {
  return (
    <svg
      onClick={props.click}
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "16"}
      height={props.height || "16"}
      style={{ cursor: "pointer" }}
      fill="currentColor"
      className="bi bi-list bi-list-icon"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
      />
    </svg>
  );
}
